import { MemberShipListTable } from '@/components/entities/company/MemberShipListTable'
import { OwnerShipListTable } from '@/components/entities/company/OwnerShipListTable'
import { ButtonLinkBack } from '@/components/lib/ButtonLinkBack'
import { Loading } from '@/components/lib/Loading'
import { Title } from '@/components/lib/Title'
import { useUserRole } from '@/hooks/useUserRole'
import AuthService from '@/services/Auth/AuthService'
import UserService from '@/services/User/UserService'
import { UserType } from '@/types/UserType'
import { getRoleUserText, setDate } from '@/utils/Functions'
import { flashStore } from '@/utils/Store'
import * as Sentry from '@sentry/react'
import { AxiosError, AxiosResponse } from 'axios'
import { Edit } from 'lucide-react'
import React from 'react'
import { Badge, Card, Col, Image, Row } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

export const UserAccount = () => {
  /* States / Hooks
   *******************************************************************************************/
  const [data, setData] = React.useState<UserType>()
  const flash = flashStore()
  const param = useParams()
  const myRole = useUserRole()

  /* useEffect
   *******************************************************************************************/
  React.useEffect(() => {
    if (param.id_user) {
      getData(Number(param.id_user))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param.id_user])

  /* Functions / Events
   *******************************************************************************************/
  const getData = (id_user: number) => {
    UserService.getUser(id_user)
      .then((response: AxiosResponse) => {
        setData(response.data)
      })
      .catch((error: AxiosError) => {
        AuthService.refreshToken()
        flash.setMessageErrorFetch()
        Sentry.captureException(error)
      })
  }

  /* Render
   *******************************************************************************************/
  return (
    <>
      <Row>
        <Col>
          <Title name={'Profil'} />
        </Col>
        <Col>
          <ButtonLinkBack />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col lg={3}>
          <Card className='py-3'>
            <Card.Body>
              {!data ? (
                <Loading />
              ) : (
                <>
                  <div className='text-center'>
                    <Image
                      src={'/img/avatar-default.png'}
                      alt='Image'
                      className='img-fluid mb-3'
                      width={100}
                    />
                  </div>
                  <div className='text-center mb-4'>
                    <Card.Title>{data?.firstName + ' ' + data?.lastName}</Card.Title>
                    {data?.ownerships?.length > 0 ? (
                      <Badge bg='primary pt-2 pb-1 mb-3'>Propriétaire</Badge>
                    ) : (
                      <Badge bg='primary pt-2 pb-1 mb-3'>{getRoleUserText(data)}</Badge>
                    )}
                    <br />
                    <small className='text-muted'>
                      Date création : {setDate(data?.createdAt)}
                    </small>
                  </div>
                  <hr />
                  <p>
                    Username :<span className='float-end'>{data?.username}</span>
                  </p>
                  <p>
                    Email :<span className='float-end'>{data?.email}</span>
                  </p>
                  <p>
                    Tél :<span className='float-end'>{data?.phone}</span>
                  </p>
                  {(data?.roles.includes('ROLE_SHIPPER') ||
                    data?.roles.includes('ROLE_TECH')) && (
                    <p>
                      Apm Code :<span className='float-end'>{data?.apmAccessCode}</span>
                    </p>
                  )}
                  <p>
                    Statut :
                    <span className='float-end'>{data?.enabled ? 'Activé' : 'Désactivé'}</span>
                  </p>

                  {myRole === import.meta.env.VITE_APP_ROLE_SUPER_ADMIN && (
                    <>
                      <hr />
                      <Link
                        className='btn btn-sm btn-dark d-block'
                        to={'/utilisateurs/modification/' + data?.id}
                      >
                        <Edit className='me-2' size={20} />
                        Modifier le profil
                      </Link>
                    </>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={9}>
          {data && data?.memberships.length > 0 && (
            <MemberShipListTable memberships={data?.memberships} />
          )}
          {data && data?.ownerships.length > 0 && (
            <OwnerShipListTable ownerships={data?.ownerships} />
          )}
        </Col>
      </Row>
    </>
  )
}
